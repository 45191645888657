<template>
  <div class="grey--text" @click="showTaskDetail(task)">
    <OpenSubTasksBtn v-bind="$props" />
    <span class="cr-text-deco text-truncate font-weight-medium" :title="title">
      {{ title }}
    </span>
  </div>
</template>

<style lang="scss" scoped>
.task-title {
  z-index: 1;
  display: flex;
  align-items: center;
}
</style>

<script>
import { mapActions } from "vuex";
import OpenSubTasksBtn from "./OpenSubTasksBtn.vue";

export default {
  components: { OpenSubTasksBtn },
  props: {
    task: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    title() {
      return this.task["title"] || "";
    }
  },
  methods: {
    ...mapActions("flowTasks", ["showTaskDetail"])
  }
};
</script>
