<template>
  <div v-if="showSubTasks" class="cr-open-sub-btn-wrapper">
    <v-btn icon small class="mr-1" :loading="loading" @click.stop="click">
      <v-icon size="20">mdi-file-tree</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  props: {
    task: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return { loading: false };
  },
  computed: {
    ...mapState("flowTasks", ["openSubTasks"]),
    showSubTasks() {
      return this.task.subCnt > 0;
    }
  },
  methods: {
    ...mapMutations("flowTasks", [
      "ADD_OPEN_SUB_TASKS",
      "DELETE_OPEN_SUB_TASKS"
    ]),
    ...mapActions("flowTasks", ["getSubTasks"]),
    async click() {
      const { id: taskId } = this.task;
      const index = this.openSubTasks.indexOf(taskId);
      if (index !== -1) return this.DELETE_OPEN_SUB_TASKS(index);

      this.loading = true;
      await this.getSubTasks(taskId);
      this.ADD_OPEN_SUB_TASKS(taskId);

      setTimeout(() => {
        this.loading = false;
      }, 250);
    }
  }
};
</script>
